<template>
  <div class="row">
    <div :class="'col-lg-12'">
      <div class="card">
        <div class="card-body">
          <form class="form-horizontal">
            <div class="form-body">
              <h3 class="box-title">Jawaban Disposisi</h3>
              <hr class="m-t-0">
              <template>

              </template>
              <template>
                <div class="row m-t-10">
                  <div class="col-md-12">
                    <label :class="['control-label',  'col-md-12']">Keterangan</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 m-t-10 m-b-10">
                    <wysiwyg v-model="myHTML"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label :class="['control-label', 'col-md-12']">File Lampiran</label>
                      <div class="col-md-12 col-sm-12">
                        <Gallery :uploader="uploader" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <hr>
            <div class="form-actions">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-offset-3 col-md-9">
                      <button
                        type="button"
                        @click="submit()"
                        class="btn btn-success"
                        title="Submit"
                      >Submit</button>&nbsp;
                      <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-6"></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";

export default {
  components: {
    Upload,
    Input,
    Multiselect
  },
  computed: {
    isInvalid() {
      return this.isTouched && this.value.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidPersonal() {
      return (
        this.dropdownPersonal.isTouched &&
        this.dropdownPersonal.value.length === 0
      );
    }
  },
  data() {
    const uploader = this.fineUploader(this,'disposition_response');
    return {
      uploader,
      files:[],
      myHTML: ""
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        this.$store.dispatch("dispositionIn/getDispositionInById", this.$route.params);
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    goBack() {
      this.$store.dispatch("disposisi/onCancel");
    },
    submit() {
      const payload = {
        agenda_number: this.$store.state.dispositionIn.detail.agenda_number,
        files: this.files,
        description: this.myHTML
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          this.$store.dispatch("dispositionIn/submitJawaban", data);
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

